:root {
  // Colors
  --font-white: #fff;
  --font-dark: #181c32;
  --font-danger: #f1416c;
  --font-text-secondary: #d2ab39;
  --font-secondary-light: #e8fff3;
  --font-primary: #2f345f;
  --font-secondary-active: #d2ab39;
  --font-text-gray-500: #a1a5b7;
  --font-text-gray-700: #5e6278;
  --font-text-gray-600: #7e8299;
  --font-text-gray-800: #3f4254;
  --font-text-gray-900: #181c32;
  --font-text-muted: #a1a5b7;
  // Colors Inputs
  --font-input-color: #5e6278;
  --font-input-border-color: #e1e3ea;
  --font-input-focus-border-color: #b5b5c3;
  --font-input-placeholder: #a1a5b9;
  --font-input-error: #f1416c;

  --font-card-border-color: #f4f4f4;

  --box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

  --border-radius-1: 0.625rem;
  --border-radius-2: 0.475rem;
}
