@import './variables';


@mixin flexCenter {
     display: flex;
     justify-content: center;
     align-items: center;
}

@mixin buttonStyle {
     border: none;
     padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
     color: var(--font-white);
     font-size: 1.1rem;
     font-weight: 600;
     border-radius: var(--border-radius-2);

     &:disabled {
          cursor: not-allowed;
          opacity: 0.7;
     }

     &:focus {
          border: none;
          outline: 0;
     }
}

@mixin titleSction {
     font-weight: 600;
     color: var(--font-dark);
     font-size: 1.3rem;
     line-height: 1.2;

     @media (max-width:991px) {
          font-size: 1.1rem;
     }
}

@mixin cardTahkeem {
     background-color: var(--font-white);
     border: 1px solid var(--font-card-border-color);
     padding: 2.25rem;
     padding-bottom: 1rem;
     margin: 30px 0;
     border-radius: var(--border-radius-2);

     @media (max-width:768px) {
          padding-inline: 1rem;
          padding-block: 1rem;
          margin-top: 15px;
     }
}