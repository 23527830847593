@import "./custom/mixins";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Google Fonts. */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;800&display=swap");

body,
html {
  font-family: "Noto Sans Arabic", "Inter", sans-serif;
  font-size: 13px !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: #fbfbfb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  padding-inline-start: 10px;
  transition: 0.3s;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #d6d6e3;
}

::-webkit-scrollbar-thumb:hover {
  background: #bebecd;
}

.table-thakeem {
  .ant-spin {
    color: var(--font-text-secondary);

    .ant-spin-dot-item {
      background-color: var(--font-text-secondary);
    }
  }

  .ant-table-thead > tr {
    @media (max-width: 768px) {
      display: flex;
    }

    & > th {
      text-align: start;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      color: var(--font-text-muted);
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      background-color: transparent !important;

      .ant-table-column-sorters {
        justify-content: flex-start;

        .ant-table-column-sorter-down.active,
        .ant-table-column-sorter-up.active {
          color: var(--font-secondary-active);
        }

        .ant-table-column-title {
          flex: none;
          padding-inline-end: 5px;
        }
      }

      &:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        content: none;
      }
    }
  }

  .ant-table-tbody > tr > td {
    font-weight: 500;
    color: var(--font-dark);

    .action-table {
      span {
        color: var(--font-text-muted);
        padding: 5px;
        border-radius: var(--border-radius-2);
        cursor: pointer;
        margin-inline-end: 10px;
        display: inline-block;
        font-size: 1.2rem;

        &:last-child {
          margin-inline-end: 0;
        }
      }

      .delete-svg {
        &:hover {
          color: var(--font-danger);
          background-color: #f2e1e5;
        }
      }

      .edit-svg {
        &:hover {
          color: var(--font-text-secondary);
          background-color: var(--font-secondary-light);
        }
      }
    }
  }

  .ant-pagination-item {
    border: none;
    font-family: "Inter", sans-serif;
    border-radius: var(--border-radius-2);
    font-weight: 500;
    font-size: 1.075rem;

    &:hover {
      background-color: #f4f6fa;

      a {
        color: var(--font-secondary-active);
      }
    }

    a {
      text-decoration: none;
      color: var(--font-input-color);
    }

    &-active {
      background-color: var(--font-secondary-active);

      &:hover {
        background-color: var(--font-secondary-active);

        a {
          color: var(--font-white) !important;
        }
      }

      a {
        color: var(--font-white);
      }
    }
  }

  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    border: none;
    font-size: 1rem;

    .anticon {
      vertical-align: 0;
    }

    &:hover {
      background-color: #f4f6fa;
      color: var(--font-secondary-active);
    }
  }
}

.form-tahkeem {
  .input-container {
    position: relative;

    .label-tahkeem,
    .label-select-tahkeem {
      padding: 0 0.5rem;
      color: var(--font-input-placeholder);
      font-weight: 500;
      font-size: 1.1rem;
      background-color: var(--font-white);
      transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    }

    .label-tahkeem {
      cursor: text;
      inset-inline-start: 0.5rem;
      top: 0.7rem;
      position: absolute;
    }

    .label-tel {
      color: var(--font-input-placeholder);
      font-weight: 500;
      position: absolute;
      top: -1.8rem;
      font-size: 1rem;
      inset-inline-start: 0.2rem;
    }

    .label-select-tahkeem {
      position: absolute;
      z-index: 2;
      top: 10px;
      inset-inline-start: 0.5rem;

      &.animate {
        top: -1.8rem;
        font-size: 1rem;
        inset-inline-start: 0.2rem;
      }
    }

    .input-tahkeem {
      width: 100%;
      padding: 0.775rem 1rem;
      font-size: 1.1rem;
      line-height: 1.5;
      font-weight: 500;
      border-radius: var(--border-radius-2);
      color: var(--font-input-color);
      border: 1px solid var(--font-input-border-color);
      outline: none;
      background: none;
      /* Change border when input focus*/

      &:focus {
        border-color: var(--font-input-focus-border-color);
      }

      &.select {
        padding-block: 4.5px 0px;
        height: 43px;
        padding-inline: 0px;
      }
    }

    .ant-input-affix-wrapper {
      border: none;
      padding: 0;
      border-radius: 6px;

      &:focus {
        box-shadow: none !important;
      }

      .ant-input-suffix {
        margin-left: 4px;
        position: absolute;
        top: 50%;
        inset-inline-end: 12px;
        transform: translateY(-50%);

        .anticon {
          color: var(--font-text-gray-700);
          cursor: pointer;
        }
      }
    }

    .input-tahkeem:focus ~ .label-tahkeem,
    .input-tahkeem:not(:placeholder-shown).input-tahkeem:not(:focus)
      ~ .label-tahkeem {
      top: -1.8rem;
      font-size: 1rem;
      inset-inline-start: 0.2rem !important;
    }

    .errors-inputs {
      margin-top: 0.5rem;
      font-size: 0.925rem;
      color: var(--font-input-error);
      font-weight: 400;
      transition: 0.3s;
    }
  }

  .tahkeem-submit-btn {
    @include buttonStyle;
    background-color: var(--font-primary);

    &.forms {
      background-color: var(--font-text-secondary);
    }

    .anticon {
      vertical-align: 0rem;
      color: var(--font-white);
      font-size: 1.5rem;
      padding-inline-start: 10px;
    }
  }

  .tahkeem-cancel-btn {
    @include buttonStyle;
    color: var(--font-text-gray-500);

    &.forms {
      color: var(--font-text-gray-700);
    }
  }

  &.form-inline {
    .input-container {
      .lable-file {
        cursor: pointer;

        .icon-uploed {
          background: var(--font-secondary-active);
          color: var(--font-white);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          height: 37px;
          position: absolute;
          inset-inline-end: 49px;
          inset-block-start: 27px;
          width: 37px;
          border-radius: 0px var(--border-radius-2) var(--border-radius-2) 0px;
        }
      }

      .label-tahkeem,
      .label-select-tahkeem {
        font-size: 1rem;
        cursor: text;
        inset-inline-start: 0;
        position: static;
      }

      .input-tahkeem {
        padding: 0.5rem 1rem;
        border: 1px solid var(--font-input-border-color);
        background-color: var(--font-white);
      }
    }
  }

  .iti--allow-dropdown input[type="tel"] {
    width: 100%;
    outline: 0;
    color: var(--font-input-color);
    font-weight: 500;
    font-size: 1.1rem;
    border: 1px solid var(--font-input-border-color);
    background-color: var(--font-white);
    border-radius: var(--border-radius-2);
    margin-block-end: 10px;
    padding: 0.8rem 1rem 0.8rem 7rem;
    line-height: 1.5;

    &::placeholder {
      color: var(--font-input-placeholder);
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .selected-dial-code {
    color: var(--font-input-placeholder);
  }

  .iti {
    display: block;

    & * {
      font-family: "Inter", sans-serif !important;
    }
  }

  .dropdown-menu.country-dropdown.show {
    transform: translateY(-87px) !important;
    width: 100%;
  }

  .iti--allow-dropdown .iti__flag-container {
    display: block;
    width: 100%;
  }

  .iti__country-list {
    overflow-x: hidden;
  }

  .iti__country-list::-webkit-scrollbar {
    width: 5px;
    background-color: #ccc;
  }

  .iti__country-list::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 8px;
  }

  .iti input#country-search-box:focus {
    border: 0;
    outline: 0;
  }

  .iti input#country-search-box {
    padding: 8px 6px;
  }

  .iti__country {
    padding: 12px 10px;
    border-block-end: 1px solid rgb(231, 230, 230);
  }

  .iti__divider {
    border-bottom: 0;
  }
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
  border: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-radius: var(--border-radius-2);
  box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
  padding: 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
  line-height: 18px;
  padding: 0 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--font-input-color);
  align-items: center;
}

.ant-breadcrumb {
  nz-breadcrumb-item {
    cursor: pointer;
    color: var(--font-text-muted);
    font-weight: 500;
    font-size: 0.95rem !important;
    transition: 0.4s;

    &:hover {
      color: var(--font-secondary-active);
    }
  }

  .ant-breadcrumb-separator {
    font-weight: 900;
    margin: 0 5px;
  }
}

.card-tahkeem {
  @include cardTahkeem;

  .form-tahkeem {
    margin-block-end: 1.25rem;

    .input-tahkeem {
      background-color: #f9f9f9;
      border-color: transparent;
      color: #5e6278;

      &.select {
        background: none;
        border: 1px solid var(--font-input-border-color);
      }
    }
  }
}

.checkbox-card {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  color: var(--font-input-placeholder);
  font-weight: 500;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .errors-inputs {
    margin-top: 0.5rem;
    font-size: 0.925rem;
    color: var(--font-input-error);
    font-weight: 400;
    transition: 0.3s;
  }

  &:hover {
    input ~ .checkmark {
      background-color: #ccc;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .checkmark {
        background-color: var(--font-secondary-active);
      }

      ~ .checkmark:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: var(--border-radius-2);

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 3px;
      width: 8px;
      height: 14px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.card-radio {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  margin-inline-end: 15px;
  border-radius: var(--border-radius-2);
  border: 1px solid var(--font-input-border-color);
  padding: 1rem;
  cursor: pointer;

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    opacity: 0;
    cursor: pointer !important;
  }

  .checkmark {
    width: 20px;
    height: 20px;
    border: solid 1px var(--font-input-border-color);
    border-radius: 50%;
    margin-inline-end: 1rem;
    position: relative;
    transition: 0.3s all;

    &::after {
      position: absolute;
      opacity: 0;
      content: "";
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--font-secondary-active);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .value {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    color: var(--font-input-color);
  }

  .decoration-card {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px var(--font-text-secondary);
    background-color: rgba(23, 184, 124, 0.12);
    pointer-events: none;
    border-radius: var(--border-radius-2);
    transition: 0.3s all;
  }
}

.card-radio input[type="radio"]:checked ~ .checkmark {
  border-color: var(--font-secondary-active);

  &:after {
    opacity: 1;
  }
}

.card-radio input[type="radio"]:checked ~ .decoration-card {
  opacity: 1;
}

.card-radio input[type="radio"]:checked ~ .value {
  font-weight: 600;
  color: var(--font-dark);
}

.ant-modal {
  top: 30px;
  width: 80% !important;

  @media (max-width: 768px) {
    width: 90% !important;
  }
}

.ant-modal-content {
  border-radius: var(--border-radius-1);

  .ant-modal-close-x {
    font-size: 20px;
  }

  .ant-modal-header {
    border-radius: var(--border-radius-1);
    border-color: var(--font-card-border-color);
    padding: 1.75rem 1.75rem;

    .ant-modal-title {
      .title-modal-tahkeem {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0;
        color: var(--font-dark);
      }
    }
  }
}

.ant-modal-footer {
  padding: 1rem;
}

.loading-spin {
  .ant-spin-dot-item {
    color: var(--font-secondary-active);
    font-size: 2.5rem;
  }

  .anticon {
    vertical-align: 0rem;
    color: var(--font-secondary-active);
    font-size: 2.5rem;
  }
}

.ant-select-dropdown {
  border-radius: var(--border-radius-1);
}

.not-found {
  p {
    color: var(--font-text-muted);
    font-size: 1rem;
  }

  button {
    display: inline-block;
    margin-top: 10px;
    @include buttonStyle;
    font-size: 1rem !important;
    background-color: var(--font-text-secondary);
    padding: 5px 10px;
  }
}

nz-spin {
  display: inline-block;
}

.ant-switch-checked {
  background-color: var(--font-secondary-active);
}
